import he from 'he';

import generateNumber from '../../../utils/generateNumber';
import { WidgetId } from '../../types/Widget';
import type { Widget } from '../../types/Widget';
import type Localiser from '../Localiser';
import type { Site } from '../sites/types/Site';

import BaseFeatures, { SiteId } from './BaseFeatures/BaseFeatures';
import { getLabelAPIParams } from './getLabelFeatures';
import FeatureId from './types/FeatureId';
import FeatureType from './types/FeatureType';
import FeatureValue from './types/FeatureValue';

export default class extends BaseFeatures {
  private origin: string;

  constructor(
    attributes: Record<string, string | undefined>,
    widget: Widget,
    editorial: boolean,
    platform: string,
    localiser: Localiser,
    site: Site,
    territory: string,
    keywords: string[] | null,
    origin?: string,
  ) {
    super(attributes, widget, editorial, platform, localiser, site, territory, keywords);
    this.origin =
      origin || (typeof window === 'undefined' ? 'widgets-nodejs' : 'widgets-clientside');

    this.features = [
      {
        id: FeatureId.MODEL_NAME,
        type: FeatureType.TEXT,
        value: FeatureValue.MODEL_NAME,
      },
      {
        id: FeatureId.TENANCY,
        type: FeatureType.TEXT,
        value: FeatureValue.TENANCY,
      },
      {
        id: FeatureId.ARTICLE_TYPE,
        type: FeatureType.TEXT,
        value: FeatureValue.ARTICLE_TYPE,
      },
      {
        id: FeatureId.ARTICLE_CATEGORY,
        type: FeatureType.TEXT,
        value: FeatureValue.ARTICLE_CATEGORY,
      },
      {
        id: FeatureId.LANGUAGE,
        type: FeatureType.TEXT,
        value: FeatureValue.LANGUAGE,
        getDefaultValue: (): string => {
          const { localiser } = this;

          return localiser?.language;
        },
      },
      {
        id: FeatureId.SITE,
        type: FeatureType.TEXT,
        value: FeatureValue.SITE,
        getDefaultValue: (): string => {
          const { site } = this;
          return (site?.value || '').toUpperCase();
        },
      },
      {
        id: FeatureId.AREA,
        type: FeatureType.TEXT,
        value: FeatureValue.AREA,
      },
      {
        id: FeatureId.NOW,
        type: FeatureType.TEXT,
        value: FeatureValue.NOW,
      },
      {
        id: FeatureId.ALLOW_OUT_OF_STOCK,
        type: FeatureType.NUMBER,
        value: FeatureValue.ALLOW_OUT_OF_STOCK,
      },
      {
        id: FeatureId.COMBINE_PRODUCT_TYPES,
        type: FeatureType.NUMBER,
        value: FeatureValue.COMBINE_PRODUCT_TYPES,
        getDefaultValue: (): number | null => {
          const { widget } = this;
          switch (widget?.id) {
            case WidgetId.VULTURE:
            case WidgetId.MERCHANT_OVERVIEW:
            case WidgetId.MOST_POPULAR_OFFERS:
            case WidgetId.TILE:
            case WidgetId.STREAMING:
            case WidgetId.SCROLLABLE_TILE:
              return 1;
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.CACHE_BUST,
        type: FeatureType.TEXT,
        value: FeatureValue.CACHE_BUST,
      },
      {
        id: FeatureId.MODEL_ID,
        type: FeatureType.NUMBER,
        value: FeatureValue.MODEL_ID,
      },
      {
        id: FeatureId.MATCH_ID,
        type: FeatureType.NUMBER,
        value: FeatureValue.MATCH_ID,
      },
      {
        id: FeatureId.CUSTOM_TRACKING_ID,
        type: FeatureType.TEXT,
        value: FeatureValue.CUSTOM_TRACKING_ID,
      },
      {
        id: FeatureId.MERCHANTS,
        type: FeatureType.TEXT,
        value: FeatureValue.FILTER_MERCHANTS,
      },
      {
        id: FeatureId.MERCHANT,
        type: FeatureType.TEXT,
        value: FeatureValue.FILTER_MERCHANT_NAME,
        getDefaultValue: (): string | null => {
          const { widget, site, territory } = this;
          if (
            widget?.id === WidgetId.SHOP &&
            site?.id === SiteId.MARIE_CLAIREUS &&
            territory === 'US'
          ) {
            return "Nordstrom,Farfetch,24 Sevres,Bergdorf Goodma,Macy's,MATCHESFASHION,Neiman Marcus,NET-A-PORTER,Saks Fifth Avenue";
          }

          return null;
        },
      },
      {
        id: FeatureId.CURRENT_MINUTES,
        type: FeatureType.NUMBER,
        value: FeatureValue.CURRENT_MINUTES,
      },
      {
        id: FeatureId.BATTLE,
        type: FeatureType.TEXT,
        value: FeatureValue.BATTLE,
      },
      {
        id: FeatureId.DATA,
        type: FeatureType.NUMBER,
        value: FeatureValue.FILTER_DATA,
      },
      {
        id: FeatureId.ALL_FILTERS,
        type: FeatureType.BOOL,
        value: FeatureValue.ALL_FILTERS,
        getDefaultValue: (): boolean => {
          const { widget } = this;

          switch (widget?.id) {
            case WidgetId.SPECS:
            case WidgetId.NIGHTHAWK:
              return true;
            default:
              return false;
          }
        },
      },
      {
        id: FeatureId.EXCLUDE_UNLABELLED,
        type: FeatureType.BOOL,
        value: FeatureValue.EXCLUDE_UNLABELLED,
        getDefaultValue: (): boolean => {
          const { widget } = this;

          switch (widget?.id) {
            case WidgetId.SPECS:
            case WidgetId.NIGHTHAWK:
              return true;
            default:
              return false;
          }
        },
      },
      {
        id: FeatureId.INCLUDE_SPECS,
        type: FeatureType.BOOL,
        value: FeatureValue.INCLUDE_SPECS,
        getDefaultValue: (): boolean => {
          const { widget } = this;

          switch (widget.id) {
            case WidgetId.NIGHTHAWK:
              return true;
            case WidgetId.SPECS:
              return true;
            default:
              return false;
          }
        },
      },
      {
        id: FeatureId.TEXTS,
        type: FeatureType.NUMBER,
        value: FeatureValue.FILTER_TEXTS,
      },
      {
        id: FeatureId.MONTHLY_COST,
        type: FeatureType.NUMBER,
        value: FeatureValue.FILTER_MONTHLY_COST,
      },
      {
        id: FeatureId.UPFRONT_COST,
        type: FeatureType.NUMBER,
        value: FeatureValue.FILTER_UPFRONT,
      },
      {
        id: FeatureId.MINUTES,
        type: FeatureType.NUMBER,
        value: FeatureValue.FILTER_MINUTES,
      },
      {
        id: FeatureId.CONTRACT_LENGTH,
        type: FeatureType.NUMBER,
        value: FeatureValue.FILTER_CONTRACT_LENGTH,
      },
      {
        id: FeatureId.CATEGORY,
        type: FeatureType.TEXT,
        value: FeatureValue.FILTER_CATEGORY,
      },
      {
        id: FeatureId.UPGRADES,
        type: FeatureType.TEXT,
        value: FeatureValue.FILTER_UPGRADE,
      },
      {
        id: FeatureId.WASPRICE,
        type: FeatureType.NUMBER,
        value: FeatureValue.FILTER_WAS_PRICE,
      },
      {
        id: FeatureId.POSTCODE,
        type: FeatureType.TEXT,
        value: FeatureValue.FILTER_POSTCODE,
      },
      {
        id: FeatureId.INCLUDES,
        type: FeatureType.TEXT,
        value: FeatureValue.FILTER_INCLUDES,
      },
      {
        id: FeatureId.SPEED,
        type: FeatureType.NUMBER,
        value: FeatureValue.FILTER_SPEED,
      },
      {
        id: FeatureId.TYPE,
        type: FeatureType.TEXT,
        value: FeatureValue.FILTER_TYPE,
      },
      {
        id: FeatureId.SORT,
        type: FeatureType.LIST,
        value: FeatureValue.SORT,
        label: 'Sort',
        battle: true,
        options: [
          {
            value: 'recommended',
            formatted_value: 'Recommended',
          },
          {
            value: 'name',
            formatted_value: 'Product Name',
          },
          {
            value: 'price',
            formatted_value: 'Price',
          },
          {
            value: 'merchant',
            formatted_value: 'Merchant Name',
          },
          {
            value: 'monthly_cost',
            formatted_value: 'Monthly Cost',
          },
          {
            value: 'upfront_cost',
            formatted_value: 'Upfront Cost',
          },
          {
            value: 'all_prices',
            formatted_value: 'Sort by Monthly and Upfront Price',
          },
          {
            value: 'deal_end_time',
            formatted_value: 'Deal End Time',
          },
          {
            value: 'deal_start_time',
            formatted_value: 'Deal Start Time',
          },
          {
            value: 'was_price_ratio',
            formatted_value: 'Was Price Ratio',
          },
          {
            value: 'contract_speed',
            formatted_value: 'Speed',
          },
          {
            value: 'contract_length',
            formatted_value: 'Contract Length',
          },
          {
            value: 'data',
            formatted_value: 'Data',
          },
          {
            value: 'minutes',
            formatted_value: 'Minutes',
          },
          {
            value: 'texts',
            formatted_value: 'Texts',
          },
          {
            value: 'network',
            formatted_value: 'Network Name',
          },
          {
            value: 'random',
            formatted_value: 'Random',
          },
          {
            value: 'money_saving',
            formatted_value: 'Money Saving',
          },
          {
            value: 'percentage_saving',
            formatted_value: 'Percentage Saving',
          },
          {
            value: 'offer_saving',
            formatted_value: 'Offer Saving',
          },
          {
            value: 'voucher',
            formatted_value: 'Voucher',
          },
        ],
        getDefaultValue: (): string | null => {
          const { widget } = this;

          switch (widget?.id) {
            case WidgetId.MERCHANT_OVERVIEW:
            case WidgetId.VULTURE:
            case WidgetId.TILE:
              return 'voucher';
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.DEAL_START_TIME_FROM,
        type: FeatureType.DATE_TIME,
        value: FeatureValue.DEAL_START_TIME_FROM,
      },
      {
        id: FeatureId.DEAL_START_TIME_TO,
        type: FeatureType.DATE_TIME,
        value: FeatureValue.DEAL_START_TIME_TO,
      },
      {
        id: FeatureId.DEAL_END_TIME_FROM,
        type: FeatureType.DATE_TIME,
        value: FeatureValue.DEAL_END_TIME_FROM,
      },
      {
        id: FeatureId.DEAL_END_TIME_TO,
        type: FeatureType.DATE_TIME,
        value: FeatureValue.DEAL_END_TIME_TO,
      },
      {
        id: FeatureId.RAND,
        type: FeatureType.NUMBER,
        value: FeatureValue.RAND,
        getDefaultValue: (): number | null => {
          const { attributes } = this;

          if (attributes?.sort === 'random') {
            // Send the rand parameter when using random sort to ensure we don't always hit
            // the same cached response
            // The number of cache entries will be equal to the value passed to generateNumber
            return generateNumber(100);
          }

          return null;
        },
      },
      {
        id: FeatureId.DESC,
        type: FeatureType.NUMBER,
        value: FeatureValue.DESC,
        label: 'Sort Descending',
        battle: true,
      },
      {
        id: FeatureId.OFFSET,
        type: FeatureType.NUMBER,
        value: FeatureValue.OFFSET,
      },
      {
        id: FeatureId.PRESERVE_ALL_MATCHES,
        label: 'Preserve All Matches',
        type: FeatureType.NUMBER,
        value: FeatureValue.PRESERVE_ALL_MATCHES,
        battle: true,
        getDefaultValue: (): null => null,
      },
      {
        id: FeatureId.DEALS_PER_MODEL,
        label: 'Deals per Model',
        type: FeatureType.NUMBER,
        value: FeatureValue.DEALS_PER_MODEL,
        battle: true,
        getDefaultValue: (): number | null => {
          const { widget } = this;
          switch (widget?.id) {
            case WidgetId.MULTI_MODEL_REVIEW:
            case WidgetId.PARTNER:
            case WidgetId.OVERLAY:
              return 1;
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.DISTINCT_MERCHANTS,
        label: 'Distinct Merchants',
        type: FeatureType.LIST,
        value: FeatureValue.DISTINCT_MERCHANTS,
        battle: true,
        options: [
          {
            value: 'default',
            formatted_value: 'Distinct Merchants',
          },
          {
            value: 'besteffort',
            formatted_value: 'Distinct Merchants Best Effort (Multimodelreview)',
          },
          {
            value: 'natural',
            formatted_value: 'Distinct Merchants Natural',
          },
        ],
        getDefaultValue: (): string | null => {
          const { widget } = this;
          switch (widget?.id) {
            case WidgetId.MULTI_MODEL_REVIEW:
            case WidgetId.PARTNER:
              return 'besteffort';
            case WidgetId.SHOP:
            case WidgetId.VULTURE:
              return 'natural';
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.DISTINCT_NETWORKS,
        label: 'Distinct Networks',
        type: FeatureType.NUMBER,
        value: FeatureValue.DISTINCT_NETWORKS,
        battle: true,
        getDefaultValue: (): number | null => {
          const { widget } = this;
          switch (widget?.id) {
            case WidgetId.COMPARISON:
              return 0;
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.DISTINCT_LABEL,
        label: 'Distinct Label',
        type: FeatureType.TEXT,
        value: FeatureValue.DISTINCT_LABEL,
      },
      {
        id: FeatureId.MULTI,
        label: 'Multi Model Order',
        type: FeatureType.NUMBER,
        value: FeatureValue.MULTI,
        battle: true,
        getDefaultValue: (): number | null => {
          const { widget } = this;
          switch (widget?.id) {
            case WidgetId.COMPARISON:
            case WidgetId.MULTI_MODEL_REVIEW:
            case WidgetId.PARTNER:
            case WidgetId.SHOP:
            case WidgetId.SPECS:
            case WidgetId.OVERLAY:
              return 1;
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.MULTI_SIMILAR,
        label: 'Multi Similar',
        type: FeatureType.NUMBER,
        value: FeatureValue.MULTI_SIMILAR,
        battle: true,
        getDefaultValue: (): null => null,
      },
      {
        id: FeatureId.KEEP_DUPLICITIES,
        label: 'Duplicate Products',
        type: FeatureType.NUMBER,
        value: FeatureValue.KEEP_DUPLICITIES,
        battle: true,
        getDefaultValue: (): number | null => {
          const { widget } = this;
          switch (widget?.id) {
            case WidgetId.COMPARISON:
              return 1;
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.DEDUPLICATION_STRATEGY,
        label: 'Deduplication Strategy',
        type: FeatureType.LIST,
        value: FeatureValue.DEDUPLICATION_STRATEGY,
        battle: true,
        options: [
          {
            value: 'merchant_id',
            formatted_value: 'Deduplicate based on merchant ID',
          },
          {
            value: 'currency',
            formatted_value: 'Deduplicate based on currency',
          },
          {
            value: 'image',
            formatted_value: 'Deduplicate based on image URL',
          },
        ],
        getDefaultValue: (): string | null => {
          const { widget } = this;
          switch (widget?.id) {
            case WidgetId.SHOP:
              return 'merchant_id,currency,image';
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.OFFER_EXCL,
        type: FeatureType.TEXT,
        value: FeatureValue.OFFER_EXCL,
      },
      {
        id: FeatureId.OFFER,
        label: 'Offer Filtering',
        type: FeatureType.LIST,
        value: FeatureValue.OFFER,
        battle: true,
        options: [
          {
            value: 'editors_pick_promo',
            formatted_value: "Editor's Pick Products",
          },
          {
            value: 'exclusive_promo',
            formatted_value: 'Exclusive Products',
          },
          {
            value: 'recommended_promo',
            formatted_value: 'Recommended Products',
          },
          {
            value: 'deal_of_the_week',
            formatted_value: 'Deal of the Week Products',
          },
          {
            value: 'amazon_lightning',
            formatted_value: 'Amazon Lightning Deal Products',
          },
          {
            value: 'amazon_prime',
            formatted_value: 'Amazon Prime Deal Products',
          },
          {
            value: 'deal_text_promo',
            formatted_value: 'Deal Text Products',
          },
        ],
      },
      {
        id: FeatureId.RELATED_MODELS,
        label: 'Related Models',
        type: FeatureType.NUMBER,
        value: FeatureValue.RELATED_MODELS,
        battle: true,
      },
      {
        id: FeatureId.PRODUCT_TYPES,
        label: 'Product Types',
        type: FeatureType.LIST,
        value: FeatureValue.FILTER_PRODUCT_TYPES,
        options: [
          {
            value: 'deals',
            formatted_value: 'Only Show Retail Deals',
          },
          {
            value: 'contracts',
            formatted_value: 'Only Show Contract Deals',
          },
          {
            value: 'subscriptions',
            formatted_value: 'Only Show Subscription Deals',
          },
          {
            value: 'broadband',
            formatted_value: 'Only Show Broadband Deals',
          },
          {
            value: 'accessories',
            formatted_value: 'Only Show Accessory Deals',
          },
          {
            value: 'fallback',
            formatted_value: 'Only Show Fallback Deals',
          },
          {
            value: 'offers',
            formatted_value: 'Only Show Offers',
          },
          {
            value: 'vouchers',
            formatted_value: 'Only Show Voucher Deals',
          },
          {
            value: 'offer_deals',
            formatted_value: 'Only Show MVC Deals',
          },
          {
            value: 'similar',
            formatted_value: 'Only Show View Similar Deals',
          },
          {
            value: 'unique_codes',
            formatted_value: 'Only Show Unique Codes Deals',
          },
          {
            value: 'multimedia',
            formatted_value: 'Only Show Multimedia Deals',
          },
          {
            value: 'newsletter',
            formatted_value: 'Only Show Newsletter Deals',
          },
          {
            value: 'rewards',
            formatted_value: 'Only Show Reward Deals',
          },
        ],
        battle: true,
        getDefaultValue: (): string | null => {
          const { attributes, widget, site } = this;
          const defaultValue = ((): string | null => {
            // We set the default here to ensure we send a missing event when
            // no deals are found (we might not display them in the widget component)
            // This should match the setup for the widget - e.g. we should only
            // include product types which we have a component setup for
            switch (widget?.id) {
              case WidgetId.MULTI_MODEL_REVIEW:
              case WidgetId.ANF_MULTIMODEL:
              case WidgetId.PARTNER:
                return 'contracts,deals,subscriptions';
              case WidgetId.POCKET:
              case WidgetId.PRODUCT_NAME:
                return 'contracts,deals,similar';
              case WidgetId.BESTOF:
              case WidgetId.MAGAZINE_SUBSCRIPTIONS:
              case WidgetId.MONA_WIDGET:
              case WidgetId.SHOP:
                return 'deals';
              case WidgetId.BUNDLE:
              case WidgetId.DEAL:
              case WidgetId.DOTW:
              case WidgetId.TABS:
              case WidgetId.LABELS:
              case WidgetId.PEACOCK:
              case WidgetId.USER_REVIEWS:
                return 'deals,similar';
              case WidgetId.BUTTON:
                return 'deals,multimedia,subscriptions,similar';
              case WidgetId.COMPARISON:
              case WidgetId.LINK:
                return 'broadband,subscriptions,contracts,deals,similar';
              case WidgetId.INBODY:
                return 'broadband,subscriptions,contracts,deals';
              case WidgetId.EDITORS_PICK:
                return 'contracts';
              case WidgetId.PRICE:
                return 'subscriptions,deals,fallback,similar';
              case WidgetId.REVIEW:
              case WidgetId.ANF_COLLECTION_TEMPLATE:
                return 'subscriptions,contracts,deals,fallback,similar';
              case WidgetId.SIMPLE:
                return 'subscriptions,contracts,deals,similar';
              case WidgetId.MERCHANT_LINK:
              case WidgetId.ANF_BUTTON:
                switch (site?.id) {
                  case SiteId.MARIE_CLAIREUS:
                  case SiteId.WH:
                  case SiteId.LIVING_ETC:
                  case SiteId.MY_IMPERFECT_LIFE:
                  case SiteId.MARIE_CLAIREUK:
                    if (attributes?.link && attributes?.linkText) {
                      return 'subscriptions,deals';
                    }
                    return 'subscriptions,deals,multimedia,similar';
                  default:
                    return 'subscriptions,deals,multimedia,similar';
                }
              case WidgetId.SPECS:
              case WidgetId.STICKY:
              case WidgetId.PRODUCT_BLOCK:
                if (attributes?.link && attributes?.linkText) {
                  return 'subscriptions,deals';
                }
                return 'subscriptions,deals,similar';
              case WidgetId.STREAMING:
                return 'subscriptions,deals,multimedia,similar';
              case WidgetId.OVERLAY:
              case WidgetId.NIGHTHAWK:
              case WidgetId.PROMOTION:
                return 'subscriptions,deals';
              case WidgetId.VULTURE:
              case WidgetId.MERCHANT_OVERVIEW:
              case WidgetId.MOST_POPULAR_OFFERS:
              case WidgetId.TILE:
              case WidgetId.SCROLLABLE_TILE:
                return 'unique_codes,vouchers,offer_deals,newsletter,rewards';
              case WidgetId.BROADBAND:
                return 'broadband';
              default:
                // Not all widgets call widget.php - e.g. seasonal
                return null;
            }
          })();

          // Setup filter product types based on dealsOnly & contractsOnly
          // Only apply the filter if the widget supports deals or contracts
          // (prevents the widget from being exploited to show unsupported deals)
          if (attributes?.dealsOnly && defaultValue && defaultValue.indexOf('deals') >= 0) {
            return 'deals';
          }
          if (attributes?.contractsOnly && defaultValue && defaultValue.indexOf('contracts') >= 0) {
            return 'contracts';
          }

          return defaultValue;
        },
      },
      {
        id: FeatureId.ROWS,
        label: 'Rows',
        type: FeatureType.NUMBER,
        value: FeatureValue.ROWS,
        battle: true,
        getDefaultValue: (): number | null => {
          const { widget, attributes } = this;
          let modelsArray: string[] = [];

          switch (widget?.id) {
            case WidgetId.ANF_BUTTON:
              if (site?.id === SiteId.WHO_WHAT_WEAR) {
                return 1;
              }
              return 3;
            case WidgetId.BESTOF:
            case WidgetId.BUTTON:
            case WidgetId.DEAL:
            case WidgetId.EDITORS_PICK:
            case WidgetId.INBODY:
            case WidgetId.LINK:
            case WidgetId.PROMOTION:
            case WidgetId.SPECS:
            case WidgetId.STICKY:
            case WidgetId.USER_REVIEWS:
            case WidgetId.PRODUCT_BLOCK:
              return 1;
            case WidgetId.ANF_COLLECTION_TEMPLATE:
              return 2;
            case WidgetId.BUNDLE:
            case WidgetId.MAGAZINE_SUBSCRIPTIONS:
            case WidgetId.MERCHANT_LINK:
            case WidgetId.PRICE:
            case WidgetId.REVIEW:
              if (attributes.name && attributes.name === 'BOT widget') {
                return 5;
              }
              return 3;
            case WidgetId.STREAMING:
            case WidgetId.MONA_WIDGET:
            case WidgetId.BROADBAND:
              return 3;
            case WidgetId.MOST_POPULAR_OFFERS:
            case WidgetId.PEACOCK:
              return 5;
            case WidgetId.SCROLLABLE_TILE:
              return 8;
            case WidgetId.TILE:
              return 9;
            case WidgetId.COMPARISON:
            case WidgetId.MULTI_MODEL_REVIEW:
            case WidgetId.ANF_MULTIMODEL:
            case WidgetId.PARTNER:
              return 10;
            case WidgetId.SHOP:
              return 12;
            case WidgetId.OVERLAY: {
              const jsonString = he
                .decode(attributes && (attributes.modelObjects as string))
                .trim()
                .replace(/(\\n|\\)/g, '');
              modelsArray = JSON.parse(jsonString);
              return modelsArray.length;
            }
            case WidgetId.VULTURE:
            case WidgetId.MERCHANT_OVERVIEW:
              return 50;
            default:
              return null;
          }
        },
      },
      {
        id: FeatureId.DEVICE,
        type: FeatureType.TEXT,
        value: FeatureValue.DEVICE,
        getDefaultValue: (): string => {
          if (typeof window !== 'undefined' && window.navigator) {
            let hasTouchScreen = false;
            if (window.navigator.maxTouchPoints > 0) {
              hasTouchScreen = true;
            } else {
              const mQ = matchMedia('(pointer:coarse)');
              if (mQ && mQ.media === '(pointer:coarse)') {
                hasTouchScreen = !!mQ.matches;
              } else if ('orientation' in window) {
                hasTouchScreen = true; // deprecated, but good fallback
              } else {
                // Only as a last resort, fall back to user agent sniffing
                const UA = navigator.userAgent;
                hasTouchScreen =
                  /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                  /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
              }
            }
            if (hasTouchScreen) {
              return 'mobile';
            }
            return 'desktop';
          }
          return 'mobile';
        },
      },
      {
        id: FeatureId.ORIGIN,
        type: FeatureType.TEXT,
        value: FeatureValue.ORIGIN,
        getDefaultValue: (): string => this.origin,
      },
      {
        id: FeatureId.PRODUCT_ID,
        type: FeatureType.TEXT,
        value: FeatureValue.PRODUCT_ID,
      },
      {
        id: FeatureId.ONLY_FALLBACK_OFFERS,
        label: 'Only Fallback Offers',
        type: FeatureType.BOOL,
        value: FeatureValue.ONLY_FALLBACK_OFFERS,
        getDefaultValue: (): boolean => false,
      },
      {
        id: FeatureId.VERIFIED,
        type: FeatureType.TEXT,
        value: FeatureValue.VERIFIED,
      },
      {
        id: FeatureId.TRANSLATE_PERCENTAGE_SAVING,
        label: 'Translate Percentage Saving',
        type: FeatureType.NUMBER,
        value: FeatureValue.TRANSLATE_PERCENTAGE_SAVING,
      },
      {
        id: FeatureId.USE_NUCLEUS_REWRITE,
        label: 'Use Nucleus Rewrite',
        type: FeatureType.NUMBER,
        value: FeatureValue.USE_NUCLEUS_REWRITE,
        battle: true,
      },
    ];
  }

  /**
   * Return the label API filters, which are dynamic so do not exist in the features array
   */
  // can be any value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLabels(): Record<string, any> {
    const { attributes } = this;
    return getLabelAPIParams(attributes);
  }
}
