import React from 'react';

import { ClickType } from '../../../../sharedModules/getClickType';
import type { Event } from '../../../../types/Analytics';
import { FreyrEventPrefix } from '../../../../types/FreyrEventPrefix';
import buildUrl from '../../../../utils/buildUrl';
import { getBaseCCUrl, getCCParams } from '../../../modules/getCCUrl';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';
import { TargetEnum } from '../TrackedLink/model';

import type { IAllDealsProps } from './model';
import styles from './styles/all-deals.css';

const AllDeals: React.FC<IAllDealsProps> = ({ deal, className, children, params }) => {
  const {
    site,
    platform,
    language,
    url: urlPath,
    articleId,
    sendAnalytics,
    allDealsLink,
    genericSharedComponents: { TrackedLink },
  } = useHawkWidgetContext();

  const getUrl = (): string => {
    const baseUrl = getBaseCCUrl(site, platform, language, urlPath);

    if (baseUrl) {
      // Set the origin based on the url & document
      // Build url params
      const newParams = {
        ...(params || {}),
        origin: ((): string => {
          if (
            /\/review/.test(urlPath) ||
            (typeof document !== 'undefined' && document.querySelector('article.review-article'))
          ) {
            return 'review';
          }
          if (typeof document !== 'undefined' && document.querySelector('#wrapper-gallery')) {
            return 'gallery';
          }
          if (/\/news\/.*-(\d+).*$/.test(urlPath)) {
            return 'news';
          }
          if (typeof document !== 'undefined' && document.querySelector('article.buyingguide')) {
            return 'buyingguide';
          }
          if (/^\/tuition\/.*\d+$/.test(urlPath)) {
            return 'tuition';
          }
          if (/\/features\//.test(urlPath)) {
            return 'features';
          }
          return '';
        })(),
      };
      return buildUrl(baseUrl, getCCParams(newParams, articleId));
    }
    return '#';
  };

  const url = getUrl();

  const hrefAttribute = allDealsLink || url;

  const trackLinkClick = (event): void => {
    if (sendAnalytics) {
      sendAnalytics(
        {
          clickType: ClickType.CC,
          prefix: FreyrEventPrefix.CLICK_FROM,
          areaClicked: 'see all deals button',
        } as Event,
        deal || null,
        event,
      );
    }
  };

  if (url) {
    return (
      <TrackedLink
        trackLinkClick={trackLinkClick}
        customTarget={TargetEnum.SELF}
        attributes={{
          className: className && styles[className],
          href: hrefAttribute,
        }}
      >
        {children}
      </TrackedLink>
    );
  }
  return null;
};

export default AllDeals;
