type FetchResponse<T> =
  | {
      success: true;
      status: number;
      data: T | string;
    }
  | {
      success: false;
      status: number;
      data: string;
    };

export const fetchAdapter = async <T>(
  input: URL | RequestInfo,
  init?: RequestInit,
): Promise<FetchResponse<T>> => {
  if (typeof fetch === 'undefined') {
    return {
      success: false,
      status: 500,
      data: 'Fetch is undefined',
    };
  }
  try {
    const response = await fetch(input, init);
    const content = await response.text();
    try {
      const json = JSON.parse(content);
      return { success: true, status: response.status, data: json };
    } catch (_error: unknown) {
      return { success: true, status: response.status, data: content };
    }
  } catch (error: unknown) {
    return {
      success: false,
      status: 500,
      data: error instanceof Error ? error.message : 'API request failed',
    };
  }
};
