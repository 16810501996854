import { AnalyticsData, Event } from '../../types/Analytics';
import { Attribute } from '../../types/Attribute';
import { ResultBattle } from '../../types/Battle';
import { LabelPromo } from '../../types/LabelPromo';
import { Product } from '../../types/Product';
import { Review } from '../../types/Review';

export default class BaseFormatter {
  protected flattenEmptyValues: boolean;

  constructor(flattenEmptyValues: boolean) {
    this.flattenEmptyValues = flattenEmptyValues;
  }

  /**
   * Return the list of attributes to send
   */
  getAttributes(): Attribute[] {
    const attributes = [
      {
        name: 'eventCategory',
        default: 'Affiliates',
        getValue: this.getEventCategory.bind(this),
      },
      {
        name: 'eventAction',
        default: 'Unknown',
        getValue: this.getEventAction.bind(this),
      },
      {
        name: 'eventLabel',
        default: 'null',
        getValue: this.getEventLabel.bind(this),
      },
      {
        name: 'nonInteraction',
        default: 1,
        getValue: this.getNonInteraction.bind(this),
      },
    ];

    return [...attributes, ...this.getCustomAttributes()];
  }

  /**
   * Allows us to set custom attributes for a formatter, without needing to specify the standard ga attributes (e.g. eventAction, nonInteraction)
   * A specific formatter should override this method to specify the dimensions we should send and what data to send in them (e.g. VanillaFormatter.ts)
   */
  getCustomAttributes(): Attribute[] {
    return [];
  }

  getFormattedData(data: AnalyticsData): Record<string, string | number | null> {
    const attributes = this.getAttributes();
    return attributes.reduce((result, attribute): Record<string, string | number | null> => {
      const defaultValue = this.getDefaultValue(attribute);
      let value = attribute.getValue ? attribute.getValue(data) : defaultValue;

      if (typeof value === 'number' && attribute.name.indexOf('metric') === -1) {
        value = String(value);
      }

      // Don't send metrics with a null value (but allow 0)
      if (attribute.name.indexOf('metric') >= 0 && !value && value !== 0) {
        return result;
      }

      result[attribute.name] = this.getValue(value, defaultValue);
      return result;
    }, {});
  }

  /**
   * Get the default value for the attribute (e.g. event action) or return 'null' for dimensions
   * We do this for dimensions to ensure the dimensions are always sent (this is so they aren't filtered out in GA when there is no value)
   */
  getDefaultValue(attribute: Attribute): string | number | null {
    if (attribute.default) {
      return attribute.default;
    }
    if (attribute.name && attribute.name.indexOf('dimension') >= 0) {
      return 'null';
    }
    return null;
  }

  getValue(
    value: string | number | null | Array<string | number | null>,
    defaultValue: string | number | null,
  ): string | number | null {
    // Handle the value being ignored if it is a number with 0 - e.g. the index
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return defaultValue;
      }

      // Flatten the nulls to support the unified HL appeared GA event
      const nulls = value.filter((v) => v === 'null' || v === null || v === '');
      if (this.flattenEmptyValues && nulls.length === value.length) {
        return 'null';
      }

      return value
        .map((v) => {
          if (typeof v === 'number') {
            return v;
          }
          if (v) {
            // Discard " to avoid breaking the html
            return v.replace(/"/g, '');
          }

          return defaultValue;
        })
        .join('|');
    }
    if (value || typeof value === 'number') {
      return value;
    }

    return defaultValue;
  }

  getEventCategory(data: AnalyticsData): string {
    return this.getEvent(data).category;
  }

  /**
     * Example titles:
     1. Editor Phone Review widget
     2. Click from Editor Price widget
     3. TOP widget
     */
  getEventAction(data: AnalyticsData): string {
    const prefix = this.getEventPrefix(data);
    const flag = this.getEventFlag(data);
    const productType = this.getEventProductType(data);
    const component = this.getEventComponentName(data);
    const type = this.getEventType(data);

    const items: string[] = [];
    if (prefix) {
      items.push(prefix);
    }
    if (flag) {
      items.push(flag);
    }
    if (productType) {
      items.push(productType);
    }
    if (component) {
      items.push(component);
    }
    if (type) {
      items.push(type);
    }

    return items.join(' ');
  }

  getEvent(data: AnalyticsData): Event {
    return data.event;
  }

  getEventPrefix(data: AnalyticsData): string {
    return this.getEvent(data).prefix;
  }

  getEventFlag(data: AnalyticsData): string {
    return this.getEvent(data).flag;
  }

  getEventProductType(data: AnalyticsData): string {
    return this.getEvent(data).productType;
  }

  getEventComponentName(data: AnalyticsData): string {
    const event = this.getEvent(data);
    return event.component && event.component.name;
  }

  getEventComponentCategory(data: AnalyticsData): string {
    const event = this.getEvent(data);
    return event.component && event.component.category;
  }

  getEventType(data: AnalyticsData): string {
    return this.getEvent(data).type;
  }

  getEventLabel(data: AnalyticsData): string {
    return this.getEvent(data).label;
  }

  /**
   * Set non interaction to a truthy value (1) if the event isn't a click event
   */
  getNonInteraction(data: AnalyticsData): number {
    return this.getEventAction(data).indexOf('Click from ') >= 0 ? 0 : 1;
  }

  getArticleId(data: AnalyticsData): string {
    return this.getEvent(data).articleId;
  }

  getProducts(data: AnalyticsData): Product[] {
    return data.products || [];
  }

  getMatchIds(data: AnalyticsData): (number | null)[] {
    return this.getProducts(data).map((p) => p.matchId);
  }

  getMerchantNames(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.merchant && p.merchant.name);
  }

  getMerchantIds(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.merchant && p.merchant.id);
  }

  getMerchantUrls(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.merchant && p.merchant.url);
  }

  getMerchantAffiliateNetwork(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.merchant && p.merchant.network);
  }

  getModelIds(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.model && p.model.id);
  }

  getModelBrands(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => (p.model && p.model.brand ? p.model.brand : null));
  }

  getModelNames(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.model && p.model.name);
  }

  getModelParentNames(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => (p.model && p.model.parent ? p.model.parent : null));
  }

  getIndex(data: AnalyticsData): number | null {
    return this.getEvent(data).index;
  }

  getTotalDeals(data: AnalyticsData): number {
    return this.getEvent(data).totalDeals;
  }

  getCurrencyIso(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) => p.currencyIso);
  }

  getRetailPrices(data: AnalyticsData): number[] {
    return this.getProducts(data).map((p) => p.price);
  }

  getFormattedRetailPrices(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) =>
      p.currencyIso && p.price ? `${p.currencyIso} ${p.price}` : 'null',
    );
  }

  getWasPrices(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.wasPrice);
  }

  getServiceProviders(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.serviceProvider);
  }

  getProductNames(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) => p.name);
  }

  getBattleData(data: AnalyticsData): ResultBattle | null {
    const { battle } = this.getEvent(data);

    if (battle && battle.test && battle.variant) {
      return {
        origin: 'battle',
        t_name: battle.test.name,
        t_id: battle.test.id,
        v_id: battle.variant.id,
        v_name: battle.variant.name,
      };
    }
    return null;
  }

  getVoucherCodeString(data: AnalyticsData): string | null {
    const products = this.getProducts(data);
    return products.length > 0 ? products.map((p) => p.voucherCodeString || null)[0] : null;
  }

  getCustomTrackingIds(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.customTrackingId);
  }

  getLabelsAndPromos(data: AnalyticsData): Array<string | null> | null {
    const products = this.getProducts(data);
    const productResults: string[] = [];
    products.forEach((p) => {
      const labels = p.labels || [];
      const promos = p.promos || [];

      const result = [
        ...labels.map((label) => `label_${label.type}:${label.value}`),
        ...promos.map((promo) => `promo_${promo.type}:${promo.value}`),
      ].join(',');

      if (result) {
        productResults.push(result);
      } else {
        productResults.push('null');
      }
    });

    if (productResults.length === 0) {
      return null;
    }

    return productResults;
  }

  getLabels(data: AnalyticsData): Array<LabelPromo[]> {
    return this.getProducts(data).map((p) => p.labels);
  }

  getPromos(data: AnalyticsData): Array<LabelPromo[]> {
    return this.getProducts(data).map((p) => p.promos);
  }

  getPreorder(data: AnalyticsData): boolean[] {
    return this.getProducts(data).map((p) => p.preorder);
  }

  getUrls(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) => p.url);
  }

  getElementIds(data: AnalyticsData): string[] {
    return this.getEvent(data).elementIds || [];
  }

  getElementYPosition(data: AnalyticsData): string | number | null {
    return this.getEvent(data).elementYPosition;
  }

  getElementYPositionAsNumber(data: AnalyticsData): number | null {
    return this.getEvent(data).elementYPosition;
  }

  getStartDate(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.startDate);
  }

  getEndDate(data: AnalyticsData): Array<number | null> {
    return this.getProducts(data).map((p) => p.endDate);
  }

  getScope(data: AnalyticsData): Array<string | null> {
    return this.getProducts(data).map((p) => p.scope);
  }

  getTimeRemaining(data: AnalyticsData): number | null {
    return this.getProducts(data).map((p) => p.timeRemaining)[0];
  }

  getClickType(data: AnalyticsData): string {
    return this.getEvent(data).clickType;
  }

  getProductTypes(data: AnalyticsData): string[] {
    return this.getProducts(data).map((p) => p.type);
  }

  getReviews(data: AnalyticsData): Review[] {
    return data.reviews || [];
  }

  getReviewCodes(data: AnalyticsData): string[] {
    return this.getReviews(data).map((r) => r.code);
  }

  getReviewCounts(data: AnalyticsData): number[] {
    return this.getReviews(data).map((r) => r.count);
  }

  getReviewScores(data: AnalyticsData): number[] {
    return this.getReviews(data).map((r) => r.score);
  }

  getLoadTime(): string | number {
    if (process.env.NODE_ENV !== 'test' && typeof window !== 'undefined') {
      return parseInt(performance.now().toFixed(0), 10);
    }
    return 0;
  }

  getLoadTimeAsNumber(): number {
    if (process.env.NODE_ENV !== 'test' && typeof window !== 'undefined') {
      return parseInt(performance.now().toFixed(0), 10);
    }
    return 0;
  }

  getViewportTime(data: AnalyticsData): number {
    return this.getEvent(data).viewportTime;
  }

  getPageHeight(): string | number {
    return typeof document !== 'undefined' ? document.body.clientHeight : 0;
  }

  getPageHeightAsNumber(): number {
    return typeof document !== 'undefined' ? document.body.clientHeight : 0;
  }

  getBackgroundColor(data: AnalyticsData): string {
    return this.getEvent(data).backgroundColor;
  }

  getBlockLayout(data: AnalyticsData): string | null {
    return this.getEvent(data).blockLayout;
  }

  getAreaClicked(data: AnalyticsData): string {
    return this.getEvent(data).areaClicked;
  }

  getPaywallStatus(data: AnalyticsData): string | null {
    if (this.getEvent(data).paywallStatus) {
      return String(this.getEvent(data).paywallStatus);
    }
    return null;
  }
}
