import type { Translate } from '../../types/Localiser';

const clickCountDailyThreshold = 5;
const clickCountWeeklyThreshold = 10;
const clickCountMonthlyThreshold = 30;
const clickCountMonthlyTopThreshold = 500;

export enum ClickCountTimeSpan {
  NONE = 'none',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  DYNAMIC = 'dynamic',
}

export const getUsageString = ({
  translate,
  clickCountTimeSpan,
  clickCountDaily = 0,
  clickCountWeekly = 0,
  clickCountMonthly = 0,
}: {
  translate: Translate;
  clickCountTimeSpan: ClickCountTimeSpan;
  clickCountDaily?: number;
  clickCountWeekly?: number;
  clickCountMonthly?: number;
}): string => {
  if (clickCountTimeSpan === ClickCountTimeSpan.NONE) {
    return '';
  }
  if (clickCountTimeSpan === ClickCountTimeSpan.DAILY) {
    return translate('voucherUsedToday', [String(clickCountDaily)]);
  }
  if (clickCountTimeSpan === ClickCountTimeSpan.WEEKLY) {
    return translate('voucherUsedThisWeek', [String(clickCountWeekly)]);
  }
  if (clickCountTimeSpan === ClickCountTimeSpan.MONTHLY) {
    return translate('voucherUsedThisMonth', [String(clickCountMonthly)]);
  }
  if (clickCountDaily >= clickCountDailyThreshold) {
    return translate('voucherUsedToday', [String(clickCountDaily)]);
  }
  if (clickCountWeekly >= clickCountWeeklyThreshold) {
    return translate('voucherUsedThisWeek', [String(clickCountWeekly)]);
  }
  if (
    clickCountMonthly >= clickCountMonthlyThreshold &&
    clickCountMonthly < clickCountMonthlyTopThreshold
  ) {
    return translate('voucherUsedThisMonth', [String(clickCountMonthly)]);
  }
  if (clickCountMonthly >= clickCountMonthlyTopThreshold) {
    return translate('voucherUsedThisMonth', [`${clickCountMonthlyTopThreshold}+`]);
  }

  return '';
};
