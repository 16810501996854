import type { SignupResponseBody } from '../../../assets/types/Response';
import { SignupAdapterResponse } from '../../../core/types/PostJSON';
import { fetchAdapter } from '../fetchAdapter';

export const httpPostKiosqSignup = async (
  email: string,
  site: string,
): Promise<SignupAdapterResponse> => {
  const signupEndpoint = process.env.KIOSQ_SIGN_UP_API ?? '';
  const dataset = {
    method: 'POST',
    body: JSON.stringify({ site, email }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  };

  const response = await fetchAdapter<SignupResponseBody>(signupEndpoint, dataset);

  if (response.success && typeof response.data !== 'string') {
    return {
      success: response.data.success,
      status: response.status,
      cookie: response.data.cookie || '',
    };
  }

  return {
    success: false,
    status: response.status,
    cookie: '',
  };
};
