import React from 'react';

import { apstagAttributes, getAsinFromDeal } from '../../../../sharedModules/apstag/getAttributes';
import type { Event } from '../../../../types/Analytics';
import { FreyrEventPrefix } from '../../../../types/FreyrEventPrefix';
import type { Product } from '../../../../types/Product';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';
import { TrackedLinkAttributesProp } from '../TrackedLink/model';

import type { AffiliateLinkProps } from './model';
import { getUrl } from './modules/getUrl';
import styles from './styles/affiliate-link.css';

const AffiliateLink: React.FC<AffiliateLinkProps> = ({
  deal,
  label,
  clickType,
  linkClick,
  children,
  className,
  customTarget,
  customUrl,
  renderPlainChildren = true,
  areaClicked = '',
}) => {
  // Avoid rendering empty a tag in all cases
  if (!children) {
    return null;
  }

  const linkRef = React.useRef<null | HTMLAnchorElement>(null);
  const url = (customUrl || (deal ? getUrl(deal, label) : null)) ?? '';

  const {
    sendAnalytics,
    translate,
    genericSharedComponents: { TrackedLink },
  } = useHawkWidgetContext();

  const trackLinkClick = (event): void => {
    if (sendAnalytics) {
      const gaEvent = {
        clickType,
        prefix: FreyrEventPrefix.CLICK_FROM,
        areaClicked,
      } as Event; // TODO: remove retyping after fie-analytics types are checked
      const product = {
        url,
      } as Product;
      // Overwrite the label if it's provided - used by prime shipping 'Free Trial'
      if (label) {
        gaEvent.label = label;
      }

      sendAnalytics(gaEvent, deal, event, product);
    }

    if (linkClick && deal) {
      linkClick(deal);
    }
  };
  const ariaLabelModel =
    deal?.model && deal.model !== 'Everything' ? deal.model : deal?.model_matched?.join(',');

  if (!url || url === '#') {
    return <>{renderPlainChildren ? children : null}</>;
  }

  const attributes: TrackedLinkAttributesProp = {
    ref: linkRef,
    href: url,
    referrerPolicy: 'no-referrer-when-downgrade',
    className: className ? styles[className] : styles['container'],
    'data-product-key': deal?.product_key,
    'data-url': deal?.offer?.link,
    'data-model-id': deal?.model_id,
    'data-match-id': deal?.id,
    'data-product-type': deal?.product_type,
    'data-link-merchant': deal?.merchant.name || null,
    'data-merchant-id': deal?.merchant?.id || null,
    'data-merchant-name': deal?.merchant?.name || null,
    'data-merchant-url': deal?.merchant?.url || null,
    'data-merchant-network': deal?.merchant?.network || null,
    'aria-label':
      (ariaLabelModel &&
        deal?.merchant.name &&
        translate('ariaLabelText', [ariaLabelModel, deal.merchant.name])) ||
      translate('alternativeLinkText'),
  };

  const asin = getAsinFromDeal(deal);
  if (asin) {
    attributes[apstagAttributes.asin] = asin;
  }

  return (
    <TrackedLink
      trackLinkClick={trackLinkClick}
      customTarget={customTarget}
      attributes={attributes}
    >
      {children}
    </TrackedLink>
  );
};

export default AffiliateLink;
