import React, { useEffect } from 'react';

import { ClickType } from '../../../../../../../sharedModules/getClickType';
import { getButtonClassName } from '../../../../../../../sharedModules/vultureStyling/styling';
import { ProductTypes } from '../../../../../../../types/ProductType';
import { SiteWithPlatform } from '../../../../../../modules/sites/types/Site';
import { Deal } from '../../../../../../types/Deal';
import { Translate } from '../../../../../../types/Localiser';
import { DialogOverlay } from '../../../../DialogOverlay';
import { useHawkWidgetContext } from '../../../../HawkWidget/HawkWidgetProviderContext';
import { VoucherCode } from '../../../../HawkWidget/model';
import { TargetEnum } from '../../../../TrackedLink/model';
import { VultureInputs } from '../../../Inputs';
import { VultureTermsConditions } from '../../../TermsConditions';

import { loadUniqueCode } from './modules/loadUniqueCode';
import styles from './styles/reveal.css';

type VultureRevealProps = {
  popupDeal: Deal;
  site: SiteWithPlatform;
  translate: Translate;
  setPopupDeal: (deal: Deal | null) => void;
  uniqueCodeData: string;
  voucherCode: VoucherCode;
  setUniqueCodeData: (value: string) => void;
  setRevealedPopupMatchId: (value: number) => void;
};

export const VultureReveal: React.FC<VultureRevealProps> = ({
  popupDeal,
  site,
  translate,
  setPopupDeal,
  uniqueCodeData,
  setUniqueCodeData,
  voucherCode,
  setRevealedPopupMatchId,
}) => {
  const {
    endpoint,
    area,
    setApiCall,
    setLoading,
    genericSharedComponents: { Button, DealImage, DisplayName },
  } = useHawkWidgetContext();

  useEffect(() => {
    setRevealedPopupMatchId(popupDeal.match_id);
  }, []);

  useEffect(() => {
    if (popupDeal.product_type === ProductTypes.unique_code) {
      loadUniqueCode(
        setLoading,
        setApiCall,
        setUniqueCodeData,
        translate,
        endpoint,
        site,
        area,
        popupDeal,
      );
    }
  }, [popupDeal]);

  const merchantName = popupDeal.merchant.name;

  if (merchantName) {
    const handleCloseDialog = (): void => {
      setPopupDeal(null);
    };

    const buttonText =
      popupDeal.product_type === ProductTypes.rewards
        ? `Activate Reward and go to ${merchantName}`
        : translate('voucherMerchantMessage', [merchantName]);

    const buttonClassName = getButtonClassName(popupDeal.product_type, !!voucherCode);

    return (
      <DialogOverlay closeDialog={handleCloseDialog}>
        <div className={styles.container}>
          <div className={styles.main}>
            <DealImage
              deal={popupDeal}
              priority={['merchant']}
              className="vulture"
              width={120}
              height={95}
            />
            {popupDeal.product_type === ProductTypes.rewards ? (
              <>
                <p className={styles['display-name']}>
                  To activate your reward, make a purchase with {merchantName}
                </p>
                <p className={styles.text}>
                  Clicking the Activate Reward button will take you to the {merchantName} site and
                  allow us to track your purchase
                </p>
              </>
            ) : (
              <>
                <DisplayName deal={popupDeal} type="product" className={styles['display-name']} />
                <VultureInputs
                  voucherCode={voucherCode}
                  deal={popupDeal}
                  uniqueCodeData={uniqueCodeData}
                />
              </>
            )}
            <Button
              deal={popupDeal}
              className={buttonClassName}
              displayMerchant
              buttonText={buttonText}
              clickType={ClickType.GO_TO_RETAILER}
              customTarget={TargetEnum.BLANK}
            />
          </div>
          {popupDeal.offer.product_note && (
            <div className={styles.terms}>
              <VultureTermsConditions deal={popupDeal} />
            </div>
          )}
        </div>
      </DialogOverlay>
    );
  }

  return null;
};
