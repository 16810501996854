import React from 'react';

import { createRoot } from 'react-dom/client';

import { MasterWidgetMain } from '../../../core/components/MasterWidget';
import { WidgetId } from '../../../core/types/Widget';
import getComponent from '../../../sharedModules/getComponent';
import { hideLinkedElements } from '../../../sharedModules/hideLinkedElements';
import { setDataViewports } from '../../../sharedModules/setDataViewPorts';
import { enumFromStringValue } from '../../../utils/enumFromStringValue';
import getAPIEndpoint from '../../../utils/getAPIEndpoint';
import getRenderType from '../../../utils/getRenderType';
import getUrlPath from '../../utils/getUrlPath';
import { getJSON } from '../ajax';

import type { IRenderWidgetProps } from './model';
import type { IProcessedWidget } from './updateProcessedWidgets';

export const renderWidget = async (
  renderWidgetProps: IRenderWidgetProps,
): Promise<IProcessedWidget | null> => {
  const { el } = renderWidgetProps;
  // Regardless of widgetType, use a button widget type inside a table - HAWK-842
  const attributes = el.dataset;
  if (el.parentNode && el.parentNode.nodeName === 'TD') {
    attributes.widgetType = 'button';
  }
  const widgetType = enumFromStringValue(WidgetId, attributes.widgetType || '') || ('' as WidgetId);

  let widgetModule = await getComponent(widgetType);

  const component = (await widgetModule?.load()) as unknown as {
    default: React.FunctionComponent;
  };

  if (el && component?.default?.getInitialiserProps) {
    const { props, apiCall, type, widgetWrapper } = await component.default.getInitialiserProps(
      renderWidgetProps,
      attributes,
      getJSON,
      getRenderType(el.getAttribute('data-render-type') || '', 'editorial') === 'editorial',
      {
        articleType: renderWidgetProps.articleType,
        articleCategory: renderWidgetProps.articleCategory,
        cacheBust: renderWidgetProps.cacheBust ? new Date().getTime() : null,
        now: renderWidgetProps.now,
        battle: window.VAN && window.VAN.abTest && window.VAN.abTest.battleId,
      },
      getAPIEndpoint(renderWidgetProps.site) || '',
      getUrlPath(),
      'widgets-clientside',
      {
        id: el.getAttribute('data-merchant-id')
          ? Number(el.getAttribute('data-merchant-id'))
          : null,
        name: el.getAttribute('data-merchant-name'),
        url: el.getAttribute('data-merchant-url'),
        network: el.getAttribute('data-merchant-network'),
      },
    );
    if (type === 'success') {
      /**
       * Render the widget and hide the linked elements (e.g. POM or M101 widget).
       * We only want to do this when a widget is actually rendered
       * The linked elements themselves use 'data-hawk-fallback-ids' to allow having
       * multiple widgets links
       * We need to link multiple widgets to avoid an edge case with the stacked product
       * title on womanandhome (HAWK-667)
       */
      hideLinkedElements(props.placeholder);

      setDataViewports(props.placeholder);
    }
    // If widget changes we need to get correct component
    if (props.widgetTypeComponent !== widgetType) {
      widgetModule = await getComponent(props.widgetTypeComponent);
    }

    const root = createRoot(el);
    root.render(
      <MasterWidgetMain
        {...props}
        apiCall={apiCall}
        // We do not need component for skipped or preserved widget
        component={type !== 'skipped' && type !== 'preserved' ? widgetModule : null}
        serverSideHtml={el?.innerHTML}
        type={type}
        widgetWrapper={widgetWrapper}
      />,
    );

    // We need to keep track about processed widgets
    if (type === 'success' || type === 'preserved' || type !== 'skipped') {
      return {
        widgetId: props.widgetId,
        widgetType: props.widgetTypeComponent,
        props,
      };
    }
  }

  return null;
};
